<template>
  <v-container fluid class="ma-0 pa-3 background text-xs-justify pb-5">
    <v-layout row wrap>
      <v-flex sm12>
        <h1
          :class="{
            'font-35': $vuetify.breakpoint.smAndUp,
            'font-18': $vuetify.breakpoint.xsOnly
          }"

        >{{ $ml.get('terms_title') }}</h1>
        <p>Última Actualización: 24 de junio de 2019</p>
        <p>Los presentes Términos y Condiciones regulan la relación contractual entre los Usuarios del Sitio, (términos definidos más adelante) Y la sociedad ROGUCA S.A. DE C.V. (en los sucesivo “RUFFUS”) referente a los servicios, productos ofrecidos, comprados, pagados, entregados y/o proporcionados a través del Sitio Web https://www.ruffusbox.com Los Usuarios al momento de acceder al Sitio Web aceptan y reconocen que cuentan con capacidad de ejercicio para hacer uso del mismo. Asimismo, reconocen y aceptan que al acceder al Sitio o utilizar y/o comprar los Productos y Servicios (términos definido más adelante) que se proporcionan a través del Sitio web, se someten de forma tácita e incondicional a los presentes Términos y Condiciones emitidos y publicados de periódicamente por RUFFUS en su Sitio Web.</p>

      </v-flex>
      <v-flex sm12>
        <h2
          class="uppercase"
          :class="{
            'font-25': $vuetify.breakpoint.smAndUp,
            'font-14': $vuetify.breakpoint.xsOnly
          }"
        >DEFINICIONES</h2>

        <p
          :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
        >Para comprender los términos empleados y facilitar la lectura, a continuación se presentan las definiciones que habrán de utilizarse en estos Términos y Condiciones, mismas que podrán ser utilizadas en singular o plural sin variar su significado:</p>

        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Aviso de privacidad: Es la política de recopilación y uso de datos personales, publicada en el Sitio Web;</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >RUFFUS: Es la empresa que proporciona los servicios y productos ofrecidos dentro del Sitio Web;</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Contenido: Es toda la información, datos, texto, software, sonido, fotografías, gráficas, videos, mensajes u otros materiales puestos a disposición y publicados en el Sitio Web;</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Las Partes: Son las personas físicas o morales que se identifican como Usuario de RUFFUS, S.A.P.I de C.V.;</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Servicios: Se trata de la intermediación entre clientes y proveedores, mediante una plataforma digital que ofrece servicios relacionados a la comercialización de productos para mascotas y descuentos ofrecidos por RUFFUS;</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Sitio Web: https://www.ruffusbox.com</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Términos y Condiciones: Es la especificación de derechos y obligaciones generados a las Partes que se derivan de la relación comercial y el uso adecuado del Sitio Web;</li>
        <li
          class="pl-4"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >Usuario: Es la persona física o moral que utiliza los Servicios o accede al Sitio Web, ya sea cliente o proveedor.</li>
      </v-flex>

      <v-flex sm12>
        <h2
          class="uppercase"
          :class="{
            'font-25': $vuetify.breakpoint.smAndUp,
            'font-14': $vuetify.breakpoint.xsOnly
          }"
        >SERVICIOS Y OBJETO</h2>
        <p
          :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
        >RUFFUS, mediante su Sitio Web, ofrece servicios relacionados con productos para mascotas, específicamente perros. Primeramente, dentro de su plataforma digital los Usuarios serán cuestionados sobre el nombre y género de su mascota. Esta plataforma ofrece distintos un paquete único, el cual será entregado mensualmente, una vez ya estipulando la dirección de envío y dirección de facturación según sea el caso. El paquete que ofrece RUFFUS podrá contener cualquiera de los siguientes:</p>

        <li
          class="pl-4 mb-3"
          :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
        >
      Shampoos, juguetes, alimentos y accesorios (collares, túneles, camas, correas, casas de acampar, accesorios de limpieza, ropa y disfraces).

        </li>
        <p
          :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
        >

        A continuación, se detalla la información relevante de todos los artículos que puede contener el paquete que ofrece RUFFUS a sus Usuarios:

        </p>

        <p>ALIMENTOS Y ACCESORIOS:</p>
        <ul class="mb-3">
          <p class="mb-0">Alimentos refrigerados:</p>
          <li>Siga las instrucciones del producto.</li>
          <li>Este producto se encuentra listo para consumir.</li>
          <li>En caso de no utilizar el producto al instante después de recepción, guárdelo en el refrigerador para evitar su descomposición natural.</li>
          <li>Favor de destapar o abrir el producto antes de calentarlo en microondas.</li>
          <li>Una vez abierto, conserve el producto en el refrigerador por máximo 48 horas.</li>
          <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
        </ul>

        <ul class="mb-3">
          <p class="mb-0">Alimentos sin Refrigeración:</p>

          <li>Siga las instrucciones del producto.</li>
          <li>Los premios no deben constituir más del 10% de las calorías diarias que consume su mascota para evitar la obesidad.</li>
          <li>La administración de los premios es exclusiva responsabilidad del dueño de la mascota.</li>
          <li>Supervise la ingesta del producto.</li>
          <li>En caso de observar hipersensibilidad, temblor, vómito, diarrea y/o palidez, acuda con su médico veterinario inmediatamente.</li>
          <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
        </ul>

        <ul class="mb-3">
          Accesorios para mascota:
          <li>
            Collares y/o paliacates.
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>El producto es de uso exclusivo para perros y gatos.</li>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>

          <li>
            Túneles.
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>El producto es de uso exclusivo para perros y gatos.</li>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>

          <li>
            Camas
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota.</li>
              <li>El producto es de uso exclusivo para perros y gatos.</li>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>
          <li>
            Correas.
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota.</li>
              <li>Evite dejar a su mascota sola con el producto, podría enredarse en su cuello y sofocarlo.</li>
              <li>El producto es de uso exclusivo para perros y gatos.</li>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>

          <li>
            Ropa y/o Disfraces.
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota.</li>
              <li>Evite dejar a su mascota sola con el producto, podría enredarse en su cuello y sofocarlo.</li>
              <li>El producto es de uso exclusivo para perros y gatos.</li>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>

          <li>
            Casas de Acampar.
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>En caso de ingesta no trate de remover el producto, consulte a su médico veterinario inmediatamente.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota.</li>
              <li>No lo deje al alcance de menores de edad.</li>
              <li>El producto es de uso exclusivo para perros y gatos.</li>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>
        </ul>

        <ul class="mb-3">
          <p class="mb-0">Accesorios de limpieza:</p>
          <li class="mb-0">
            En caso de ser un spray:
            <p class="mb-0">Modo de empleo:</p>
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Agitar bien antes de usar</li>
              <li>Haga una prueba en una pequeña área oculta.</li>
              <li>Limpie con un paño, en caso de deterioro en la superficie, no utilice el producto.</li>
              <li>Evite el contacto con la piel y ojos.</li>
              <li>Manténgalo fuera del alcance de los niños y mascotas.</li>
            </ul>
          </li>

          <li>
            En caso de ser un cepillo de dientes:
            <p class="mb-0">Modo de empleo:</p>
            <ul>
              <li>Siga las instrucciones del producto.</li>
              <li>Enjuagar la boca de su mascota.</li>
              <li>Limpiar los dientes con el cepillo de dientes especial para perros</li>
              <li>Pulir los dientes hasta estar limpios.</li>
              <li>Evite el contacto con la piel y ojos.</li>
              <li>Manténgalo fuera del alcance de los niños y mascotas.</li>
            </ul>
          </li>

          <li>
            Primeros Auxilios
            <ul>
              <li>
                Humanos
                <ul>
                  <li>Aleje a la persona del área en el que está el producto concentrado.</li>
                  <li>En caso de inhalación prolongada, trasládese a un lugar ventilado.</li>
                  <li>En caso de contacto con los ojos, lave con abundante agua durante 15 minutos y si persiste la irritación consulte a su médico.</li>
                  <li>En caso de malestar por sobreexposición, lleve al paciente con un médico inmediatamente.</li>
                  <li>En caso de ingestión accidental, no provoque el vómito y acuda con un médico inmediatamente.</li>
                </ul>
              </li>
              <li>
                Mascotas:
                <ul>
                  <li>En caso de observar hipersensibilidad, temblor, vómito, diarrea y/o palidez, acuda con su médico veterinario inmediatamente.</li>
                  <li>En caso de contacto con los ojos, lave con abundante agua durante 15 minutos y si persiste la irritación consulte a su médico veterinario.</li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            Manejo para protección del ambiente:
            <ul>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>
          <li>
            Condiciones de almacenamiento y transporte:
            <ul>
              <li>Mantenga el lugar de almacenamiento y trabajo bien ventilado para minimizar la inhalación de residuos que éstos contengan, almacenar y transportar en lugar fresco y seco, alejado de materiales inflamables y productos alimenticios.</li>
            </ul>
          </li>
        </ul>

        <ul class="mb-3">
          <p class="mb-0">SHAMPOOS:</p>
          <p
            class="mb-0"
          >El producto ha sido diseñado para el baño exclusivo de perros mayores a 5 meses. Favor de leer la etiqueta y consultar con su veterinario antes de utilizar el producto.</p>

          <p class="mb-0">Vía de Administración: Cutánea. Uso externo únicamente.</p>
          <li>
            Precauciones y advertencias del uso del producto:
            <ul>
              <li>La aplicación correcta del producto es responsabilidad de la persona que lo utiliza.</li>
              <li>Evítese el contacto con los ojos al aplicar el producto.</li>
              <li>Lávese las manos después de utilizar el producto.</li>
              <li>Evítese el contacto con los ojos, nariz, hocico y genitales de la mascota.</li>
              <li>No administrar a perros lactantes.</li>
              <li>No aplique en heridas abiertas o piel irritada.</li>
              <li>utilice este producto si su mascota cuenta con piel hipersensible.</li>
              <li>Conserve el producto en un lugar obscuro, fresco y seco.</li>
              <li>No reutilice este envase.</li>
              <li>No almacene cerca de productos alimenticios y ensures de cocina.</li>
              <li>Puede ser nocivo en caso de ingesta, inhalación o al contacto con la piel.v</li>
              <li>No deje al alcance de menores de edad.</li>
            </ul>
          </li>
          <li>
            Modo de empleo:
            <ul>
              <li>Moje con agua tibia a su perro y vierta la cantidad que considere necesaria del producto, evitando el contacto con los ojos, hocico, orejas y genitales de la mascota.</li>
              <li>De masaje al pelo y piel de su mascota hasta que haya espuma.</li>
              <li>Espere unos minutos para dejar actuar al producto.</li>
              <li>Enjuague a su mascota hasta que no haya residuos del producto.</li>
              <li>Seque a su mascota.</li>
              <li>* Si observa alguna reacción desfavorable suspenda su uso y consulte a su médico veterinario.</li>
            </ul>
          </li>

          <li>
            Primeros Auxilios:
            <ul>
              <li>
                Humanos:
                <ul>
                  <li>Aleje a la persona del área en el que está el producto concentrado.</li>
                  <li>En caso de inhalación prolongada, trasládese a un lugar ventilado.</li>
                  <li>En caso de contacto con los ojos, lave con abundante agua durante 15 minutos y si persiste la irritación consulte a su médico.</li>
                  <li>En caso de malestar por sobreexposición, lleve al paciente con un médico inmediatamente.</li>
                  <li>En caso de ingestión accidental, no provoque el vómito y acuda con un médico inmediatamente.</li>
                </ul>
              </li>
              <li>
                Mascotas:
                <ul>
                  <li>En caso de observar hipersensibilidad, temblor, vómito, diarrea y/o palidez, acuda con su médico veterinario inmediatamente.</li>
                  <li>En caso de contacto con los ojos, lave con abundante agua durante 15 minutos y si persiste la irritación consulte a su médico veterinario.</li>
                  <li>Si observa a su mascota babeando, revise que no haya ingerido el producto. Acuda con su médico veterinario inmediatamente.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Manejo para protección del ambiente:
            <ul>
              <li>No contamine cuerpos de agua, arroyos, esteros y/o mar. Maneje el envase vacío conforme al Reglamento de la Ley General de Equilibrio Ecológico y la Protección Ambiental en materia de residuos peligrosos.</li>
            </ul>
          </li>

          <li>
            Condiciones de almacenamiento y transporte:
            <ul>
              <li>Mantenga el lugar de almacenamiento y trabajo bien ventilado para minimizar la inhalación de residuos que éstos contengan, almacenar y transportar en lugar fresco y seco, alejado de materiales inflamables y productos alimenticios.</li>
            </ul>
          </li>

          <li>
            Ingredientes
            <ul>
              <li>Agua</li>
              <li>Laurilpoliglucosa</li>
              <li>Extracto de abeto rojo</li>
              <li>Aceite de semilla de uva</li>
              <li>Peg-120 metil glucosa trioleato</li>
              <li>Gel de aloe vera</li>
              <li>Proteína hidrolizada de arroz</li>
              <li>Lauril glucosa carboxilato de sodio</li>
              <li>Peg-4 rapeseedamide</li>
              <li>Aceite esencial de menta</li>
              <li>Gluconato de sodio y sorbato de potasio</li>
            </ul>
          </li>
        </ul>

        <ul class="mb-3">
          <p class="mb-0">JUGUETES:</p>
          <li>
            Productos de plástico duro, silicón, plástico blando y/o masticable.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda lavar el producto habitualmente, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>En mascotas menores a 6 meses, es habitual que al masticar puedan perder dientes de leche o primarios.</li>
              <li>No suministrar el producto en mascotas con problemas dentales.</li>
              <li>Mantener el producto en un lugar fresco y sin contacto directo del sol.</li>
            </ul>
          </li>
          <li>
            Productos que sean y/o contengan cuerdas.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta no trate de remover la cuerda, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda lavar el producto habitualmente, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>En mascotas menores a 6 meses, es habitual que al masticar puedan perder dientes de leche o primarios.</li>
              <li>No suministrar el producto en mascotas con problemas dentales.</li>
              <li>Evite dejar a su mascota sola con el producto, podría enredarse en su cuello y sofocarlo.</li>
              <li>No lo deje al alcance de menores de edad.</li>
            </ul>
          </li>

          <li>
            Productos de peluche, con relleno no comestible y/o textiles de cualquier tipo.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>Lavarlo antes de utilizar con agua caliente y sin más prendas para que salgan los residuos de colorante del producto.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta no trate de remover la cuerda, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda lavar el producto habitualmente, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingiera el producto o relleno. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>En mascotas menores a 6 meses, es habitual que al masticar puedan perder dientes de leche o primarios.</li>
              <li>No suministrar el producto en mascotas con problemas dentales.</li>
              <li>Evite dejar a su mascota sola con el producto, podría enredarse en su cuello y sofocarlo.</li>
              <li>No lo deje al alcance de menores de edad.</li>
            </ul>
          </li>

          <li>
            Productos interactivos y/o con piezas desprendibles o que con el uso se puedan desprender.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda lavar el producto habitualmente, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>No suministrar el producto en mascotas con problemas dentales.</li>
              <li>Mantener el producto en un lugar fresco y sin contacto directo del sol.</li>
              <li>Los premios no deben constituir más del 10% de las calorías diarias que consume su mascota para evitar la obesidad.</li>
              <li>La administración de los premios es exclusiva responsabilidad del dueño de la mascota.</li>
              <li>Supervise la ingesta del premio.</li>
              <li>Los premios deben ser intercambiados cada 24 horas.</li>
              <li>En caso de observar hipersensibilidad, temblor, vómito, diarrea y/o palidez, acuda con su médico veterinario inmediatamente.</li>
            </ul>
          </li>

          <li>
            Productos con sonido y/o silbato.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda limpiar con un trapo húmedo el producto habitualmente, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Mantener el producto en un lugar fresco y sin contacto directo del sol.</li>
            </ul>
          </li>

          <li>
            Productos con baterías y/o luces o motores y/o equipos mecánicos.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de desprendimiento de alguna sección del producto, favor de removerlo para evitar obstrucciones en el estómago o vías respiratorias en caso de que su mascota consuma el producto.</li>
              <li>En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda limpiar con un trapo húmedo el producto habitualmente, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Mantener el producto en un lugar fresco y sin contacto directo del sol.</li>
              <li>Evite que el producto se moje.</li>
              <li>Evite que las baterías generen corrosión en el producto.</li>
              <li>Evite que el motor quede al descubierto.</li>
            </ul>
          </li>

          <li>
            Productos luminiscentes, pelotas y/o productos similares.
            <ul>
              <li>Siempre supervise a su mascota.</li>
              <li>En caso de desgaste y/o deterioro del producto, favor de reemplazarlo.</li>
              <li>En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Tenga precaución al momento de remover el producto del hocico de la mascota ya que puede lastimarle la dentadura o podría tener un accidente en caso de agresividad del animal.</li>
              <li>Revise que el tamaño del producto sea el adecuado para su mascota. Juguetes pequeños pueden causar obstrucción de las vías respiratorias de la mascota.</li>
              <li>Se recomienda lavar el producto habitualmente con agua fría, los juguetes al estar en contacto con el suelo y hocico de la mascota, puede presentar bacterias.</li>
              <li>No permita que su mascota ingesta el producto. En caso de ingesta, consulte a su médico veterinario inmediatamente.</li>
              <li>Mantener el producto en un lugar fresco y sin contacto directo del sol.</li>
            </ul>
          </li>
        </ul>

        <ul class="mb-3">
          <p class="mb-0">PERFUMES:</p>
          <p class="mb-0">Vía de Administración: Cutánea. Uso externo únicamente.</p>
          <li>
            Precauciones y advertencias del uso del producto:
            <ul>
              <li>La aplicación correcta del producto es responsabilidad de la persona que lo utiliza.</li>
              <li>Evítese el contacto con los ojos al aplicar el producto.</li>
              <li>Haga una prueba de reacción en una pequeña área del estomago de la mascota.</li>
              <li>Evítese el contacto con los ojos, nariz, hocico y genitales de la mascota.</li>
              <li>No aplique en heridas abiertas o piel irritada.</li>
              <li>No utilice este producto si su mascota cuenta con piel hipersensible.</li>
              <li>Conserve el producto en un lugar obscuro, fresco y seco.</li>
              <li>No reutilice este envase.</li>
              <li>No almacene cerca de productos alimenticios y ensures de cocina.</li>
              <li>Puede ser nocivo en caso de ingesta, inhalación o al contacto con la piel.</li>
              <li>No deje al alcance de menores de edad.</li>
            </ul>
          </li>

          <li>
            Modo de empleo:
            <ul>
              <li>Después de un baño a su mascota, utilice el producto y vierta la cantidad que considere necesaria, evitando el contacto con los ojos, hocico, orejas y genitales de la mascota.</li>
              <li>Espere unos minutos para dejar actuar al producto.</li>
              <li>* Si observa alguna reacción desfavorable suspenda su uso y consulte a su médico veterinario.</li>
            </ul>
          </li>

          <li>
            Primeros Auxilios:
            <ul>
              <li>
                Humanos:
                <ul>
                  <li>Aleje a la persona del área en el que está el producto concentrado.</li>
                  <li>En caso de inhalación prolongada, trasládese a un lugar ventilado.</li>
                  <li>En caso de contacto con los ojos, lave con abundante agua durante 15 minutos y si persiste la irritación consulte a su médico.</li>
                  <li>En caso de malestar por sobreexposición, lleve al paciente con un médico inmediatamente.</li>
                  <li>En caso de ingestión accidental, no provoque el vómito y acuda con un médico inmediatamente.</li>
                </ul>
              </li>
              <li>
                Mascotas:
                <ul>
                  <li>En caso de observar hipersensibilidad, temblor, vómito, diarrea y/o palidez, acuda con su médico veterinario inmediatamente.</li>
                  <li>En caso de contacto con los ojos, lave con abundante agua durante 15 minutos y si persiste la irritación consulte a su médico veterinario.</li>
                  <li>Si observa a su mascota babeando, revise que no haya ingerido el producto. Acuda con su médico veterinario inmediatamente.</li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            Condiciones de almacenamiento y transporte:
            <ul>
              <li>Mantenga el lugar de almacenamiento y trabajo bien ventilado para minimizar la inhalación de residuos que éstos contengan, almacenar y transportar en lugar fresco y seco, alejado de materiales inflamables y productos alimenticios.</li>
            </ul>
          </li>

          <li>
            Manejo para protección del ambiente:
            <ul>
              <li>Mantenga el lugar de almacenamiento y trabajo bien ventilado para minimizar la inhalación de residuos que éstos contengan, almacenar y transportar en lugar fresco y seco, alejado de materiales inflamables y productos alimenticios.</li>
              <li>En caso de que su mascota tenga un bloqueo de las vías respiratorias, utilice la maniobra de Heimlich para perros o RCP. Consiste en hacer saltar el objeto motivo de obstrucción hacia el exterior, a través de la boca, descrita acontinuación:</li>
            </ul>
          </li>
        </ul>

        <v-layout row wrap v-for="(term, index) in terms" :key="index">
          <v-flex xs12>
            <h2
              class="uppercase"
              :class="{
            'font-25': $vuetify.breakpoint.smAndUp,
            'font-14': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.title }}</h2>

            <p
              :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.description }}</p>

            <template v-for="(bullet, idx) in term.bullets">
              <li
                :key="idx + 'bullet' + index"
                class="pl-4"
                :class="{
              'font-14': $vuetify.breakpoint.smAndUp,
              'font-11': $vuetify.breakpoint.xsOnly
            }"
              >{{ bullet }}</li>
            </template>

            <p
              v-if="term.description_2"
              :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.description_2 }}</p>

            <p
              v-if="term.description_3"
              :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.description_3 }}</p>

            <p
              v-if="term.description_4"
              :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.description_4 }}</p>

            <p
              v-if="term.description_5"
              :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.description_5 }}</p>

            <p
              v-if="term.description_6"
              :class="{
            'font-17': $vuetify.breakpoint.smAndUp,
            'font-12': $vuetify.breakpoint.xsOnly
          }"
            >{{ term.description_6 }}</p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Terms",
  data() {
    return {
      terms: [
        {
          title: this.$ml.get("terms_title_1"),
          description: this.$ml.get("terms_description_1")
        },
        {
          title: this.$ml.get("terms_title_2"),
          description: this.$ml.get("terms_description_2"),
          bullets: [this.$ml.get("terms_bullets_2_1")]
        },
        {
          title: this.$ml.get("terms_title_3"),
          description: this.$ml.get("terms_description_3")
        },
        {
          title: this.$ml.get("terms_title_4"),
          description: this.$ml.get("terms_description_4"),
          bullets: [
            this.$ml.get("terms_bullets_4_1"),
            this.$ml.get("terms_bullets_4_2"),
            this.$ml.get("terms_bullets_4_3"),
            this.$ml.get("terms_bullets_4_4")
          ]
        },
        {
          title: this.$ml.get("terms_title_5"),
          description: this.$ml.get("terms_description_5")
        },
        {
          title: this.$ml.get("terms_title_6"),
          description: this.$ml.get("terms_description_6_1"),
          description_2: this.$ml.get("terms_description_6_2"),
          description_3: this.$ml.get("terms_description_6_3"),
          description_4: this.$ml.get("terms_description_6_4"),
          description_5: this.$ml.get("terms_description_6_5"),
          description_6: this.$ml.get("terms_description_6_6")
        },
        {
          title: this.$ml.get("terms_title_7"),
          description: this.$ml.get("terms_description_7")
        },
        {
          title: this.$ml.get("terms_title_8"),
          description: this.$ml.get("terms_description_8")
        },
        {
          title: this.$ml.get("terms_title_9"),
          description: this.$ml.get("terms_description_9")
        },
        {
          title: this.$ml.get("terms_title_10"),
          description: this.$ml.get("terms_description_10_1"),
          description_2: this.$ml.get("terms_description_10_2")
        },
        {
          title: this.$ml.get("terms_title_11"),
          description: this.$ml.get("terms_description_11_1"),
          description_2: this.$ml.get("terms_description_11_2"),
          description_3: this.$ml.get("terms_description_11_3"),
          description_4: this.$ml.get("terms_description_11_4")
        },
        {
          title: this.$ml.get("terms_title_12"),
          description: this.$ml.get("terms_description_12")
        },
        {
          title: this.$ml.get("terms_title_13"),
          description: this.$ml.get("terms_description_13")
        },
        {
          title: this.$ml.get("terms_title_14"),
          description: this.$ml.get("terms_description_14")
        },
        {
          title: this.$ml.get("terms_title_15"),
          description: this.$ml.get("terms_description_15")
        },
        {
          title: this.$ml.get("terms_title_16"),
          description: this.$ml.get("terms_description_16")
        },
        {
          title: this.$ml.get("terms_title_17"),
          description: this.$ml.get("terms_description_17")
        },
        {
          title: this.$ml.get("terms_title_18"),
          description: this.$ml.get("terms_description_18")
        },
        {
          title: this.$ml.get("terms_title_19"),
          description: this.$ml.get("terms_description_19")
        },
        {
          title: this.$ml.get("terms_title_20"),
          description: this.$ml.get("terms_description_20")
        },
        {
          title: this.$ml.get("terms_title_21"),
          description: this.$ml.get("terms_description_21")
        },
        {
          title: this.$ml.get("terms_title_22"),
          description: this.$ml.get("terms_description_22")
        },
        {
          title: this.$ml.get("terms_title_23"),
          description: this.$ml.get("terms_description_23")
        }
      ]
    };
  },
  metaInfo: {
    title: 'Términos y Condiciones',
    meta: [
      { vmid: 'description', name: 'description', content: 'Conoce nuestros términos y condiciones antes de utilizar Ruffus Box.' },
      { vmid: 'og:title', property: 'og:title', content: 'Términos y Condiciones' },
      { vmid: 'og:url', property: 'og:url', content: 'https://www.ruffusbox.com/terminos' },
      { vmid: 'og:image', property: 'og:image', content: 'https://s3-us-west-2.amazonaws.com/media.ruffusbox.com/web/imagen-meta-tags.png' },
      { vmid: 'og:description', property: 'og:description', content: 'Conoce nuestros términos y condiciones antes de utilizar Ruffus Box.' },
      { vmid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: 'Términos y Condiciones' },
      { vmid: 'twitter:title', property: 'twitter:title', content: 'Términos y Condiciones' },
      { vmid: 'twitter:image', property: 'twitter:image', content: 'https://s3-us-west-2.amazonaws.com/media.ruffusbox.com/web/imagen-meta-tags.png' },
      { vmid: 'twitter:description', property: 'twitter:description', content: 'Conoce nuestros términos y condiciones antes de utilizar Ruffus Box.' }
    ],
    link: [
      { vmid: 'canonical', rel: 'canonical', href: 'https://www.ruffusbox.com/terminos' }
    ]
  },
};
</script>

<style scoped>
</style>
